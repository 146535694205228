import { useMinBreakpoint } from '@hooks/useMediaQuery/useMediaQuery';
import React, { useEffect, useState } from 'react';

interface ILottieProps {
  className?: string;
  src?: string;
  width?: number | string;
  height?: number | string;
}
export interface ILottieAnimation extends ILottieProps {
  breakpoint?: string;
  desktop?: ILottieProps;
  mobile?: ILottieProps;
}

export default function LottieAnimation({
  className, desktop, mobile, src, width, height, breakpoint
}: ILottieAnimation) {
  const isBrowser = typeof window !== 'undefined';
  const [loaded, setLoaded] = useState(false);
  const isMobile = !useMinBreakpoint(breakpoint ?? 'desktop');

  const finalSrc = isMobile && mobile?.src ? mobile.src : desktop?.src ?? src;
  const finalWidth = isMobile && mobile?.width ? mobile.width : desktop?.width ?? width;
  const finalHeight = isMobile && mobile?.height ? mobile.height : desktop?.height ?? height;

  useEffect(() => {
    /*
      I tried to import this lib below as a regular static import
      but the simple fact of importing it errors because of
      the lack of the global 'window'
    */
    async function loadScript() {
      await import('@lottiefiles/lottie-player');
      setLoaded(true);
    }
    loadScript();
  }, []);

  return (
    <>
      <div className={className} suppressHydrationWarning>
        {isBrowser && loaded && (
          <lottie-player
            autoplay
            loop
            mode="normal"
            src={finalSrc}
            style={
              { width: finalWidth, height: finalHeight }
            }
            background="transparent"
            speed="1"
          />
        )}
      </div>
    </>
  );
}
